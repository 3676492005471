<template>
  <el-row :gutter="0">
    <el-col class="form-column" :span="6" />
    <el-col class="form-column" :span="6">
      <div class="column-subcontainer">
        <div class="text-xs font-bold">
          {{ $t('common.forms.labels.locations') }}
        </div>
        <div>{{ source }}</div>
      </div>
    </el-col>
    <el-col class="form-column" :span="6">
      <div class="column-subcontainer">
        <div class="text-xs font-bold">
          {{ $t('common.forms.labels.locations') }}
        </div>
        <div>{{ destination }}</div>
      </div>
    </el-col>
    <el-col class="form-column" :span="6" />
  </el-row>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  props: {
    row: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  computed: {
    source() {
      const locations = safeGet(this.row, 'from.locations') || []
      if (locations.length) {
        return locations.map((location) => location.name).join(', ')
      } else {
        return '-'
      }
    },
    destination() {
      const locations = safeGet(this.row, 'to.locations') || []
      if (locations.length) {
        return locations.map((location) => location.name).join(', ')
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped>
.form-column {
  margin: 0;
  padding: 10px;
}
</style>
