<template>
  <th-datatable
    class="history-table"
    resource="safesLogBook"
    :resource-query="resourceQuery"
    :headers="headers"
    :show-operations="false"
    :show-tool-header="false"
    :meta-options="metaOptions"
    expanding-row
  >
    <template #expanding-row="{ row }">
      <expanding-row :row="row" />
    </template>
  </th-datatable>
</template>

<script>
import safeGet from 'just-safe-get'
import ExpandingRow from './expanding-row'

export default {
  components: {
    ExpandingRow
  },
  data() {
    return {
      headers: [
        {
          field: 'initiated_at',
          label: this.$t(
            'pages.safe_management.edit.form.logs.table.headers.initiated_at'
          ),
          fallback: '--',
          truncate: true,
          formatter: (row, column) => {
            if (!row.initiated_at) return '--'
            return this.$date.formatDateTimeWithTimezone(
              row.initiated_at,
              row.timezone
            )
          },
          sortType: 'date'
        },
        {
          field: 'from',
          label: this.$t(
            'pages.safe_management.edit.form.logs.table.headers.from'
          ),
          fallback: '--',
          truncate: true,
          formatter: (row, column) => {
            let result = `${safeGet(row, 'from.name') || '--'}`

            const split = row.transfer_type && row.transfer_type.split('_')
            let type
            if (split && split.length === 3) {
              type = this.transferTypes[split[0]]
              result = `${result} (${type})`
            }

            const accountNumber =
              safeGet(row, 'new.from.account_number') ||
              safeGet(row, 'new.from.fa_account_number')
            if (accountNumber) {
              result = `${result} | ${accountNumber}`
            }

            return result
          }
        },
        {
          field: 'to',
          label: this.$t(
            'pages.safe_management.edit.form.logs.table.headers.to'
          ),
          fallback: '--',
          truncate: true,
          formatter: (row, column) => {
            let result = `${safeGet(row, 'to.name') || '--'}`

            const split = row.transfer_type && row.transfer_type.split('_')
            let type
            if (split && split.length === 3) {
              type = this.transferTypes[split[2]]
              result = `${result} (${type})`
            }

            const accountNumber =
              safeGet(row, 'new.to.account_number') ||
              safeGet(row, 'new.to.fa_account_number')
            if (accountNumber) {
              result = `${result} | ${accountNumber}`
            }

            return result
          }
        },
        {
          field: 'transfer_value',
          label: this.$t(
            'pages.safe_management.edit.form.logs.table.headers.amount'
          ),
          fallback: '--',
          truncate: true,
          formatter: (row, column) => {
            return this.$formatCurrency(row.transfer_value, row.currency)
          }
        }
      ]
    }
  },
  computed: {
    resourceQuery() {
      return {
        query: {
          operation: ['book'],
          exclude_errors: true,
          embed: ['to', 'from'],
          transfer_party: this.$route.params.id,
          cursor_field: '-initiated_at'
        }
      }
    },
    metaOptions() {
      return {
        query: {
          operation: ['book'],
          exclude_errors: true,
          transfer_party: this.$route.params.id
        }
      }
    },
    transferTypes() {
      return {
        pos: this.$t(
          'pages.safe_management.edit.form.logs.table.transfer_types.pos'
        ),
        safe: this.$t(
          'pages.safe_management.edit.form.logs.table.transfer_types.safe'
        ),
        expense: this.$t(
          'pages.safe_management.edit.form.logs.table.transfer_types.expense'
        ),
        bank: this.$t(
          'pages.safe_management.edit.form.logs.table.transfer_types.bank'
        )
      }
    }
  }
}
</script>

<style scoped>
.history-table :deep(.card-content) {
  border: none;
  box-shadow: none;
  width: 100%;
  height: 100%;
}

.history-table :deep(.el-table__expanded-cell) {
  padding: 0 0 0 45px;
}
</style>
