<template>
  <div class="form-wrapper h-full flex flex-col w-full">
    <div
      class="py-6 px-6 flex flex-row justify-between items-center w-full text-th-primary-blue"
    >
      <!-- Back to safe management -->
      <router-link :to="{ name: 'accounting-safe-management' }" class="text-sm">
        <el-icon class="mr-1"><ArrowLeft /></el-icon>
        <span v-text="$t('pages.safe_management.overview.route_back')" />
      </router-link>

      <!-- Edit -->
      <el-button
        v-permissions="{ scopes: ['accounting:safe_management:update'] }"
        type="primary"
        plain
        @click="handleEdit"
        v-text="$t('common.interactions.buttons.edit')"
      />
    </div>

    <el-row class="px-6 flex flex-col">
      <div class="flex justify-between">
        <!-- Name -->
        <div>
          <th-input-title
            :title="$t('pages.safe_management.edit.form.properties.name.label')"
          />
          <div>{{ safe.name || '–' }}</div>
        </div>

        <!-- Type -->
        <div>
          <th-input-title
            :title="
              $t('pages.safe_management.edit.form.properties.safe_type.label')
            "
          />
          <div v-text="translations[safe.type] || '–'" />
        </div>

        <!-- Account number -->
        <div>
          <th-input-title
            :title="
              $t(
                'pages.safe_management.edit.form.properties.account_number.label'
              )
            "
          />
          <div>{{ safe.account_number || '–' }}</div>
        </div>

        <!-- Location -->
        <div>
          <th-input-title
            :title="
              $t('pages.safe_management.edit.form.properties.location.label')
            "
          />
          <div>{{ location.name || location.branch_number || '–' }}</div>
        </div>
      </div>
    </el-row>

    <el-row class="h-full flex">
      <el-container>
        <el-main class="p-0 m-0">
          <safe-history />
        </el-main>
      </el-container>
    </el-row>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import SafeHistory from './components/safe-history/index.vue'

export default {
  name: 'SafeOverview',
  components: {
    SafeHistory
  },
  data() {
    return {
      safe: {},
      location: {},
      loading: false
    }
  },
  computed: {
    translations() {
      return {
        safe: this.$t('pages.safe_management.edit.form.safe_types.safe'),
        vault: this.$t('pages.safe_management.edit.form.safe_types.vault')
      }
    }
  },
  async mounted() {
    const id = this.$route.params.id
    if (id) await this.fetch(id)
    if (this.safe.location) this.fetchLocation(this.safe.location)
  },
  methods: {
    async fetch(id) {
      try {
        this.loading = true
        const { data = {} } = await th.safes().get(id)

        if (data.id) {
          this.safe = data
        }
      } catch (err) {
        this.$message({
          type: 'error',
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.safe.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    async fetchLocation(id) {
      try {
        this.loading = true
        const { branchesV1 } = await this.$resourceFetch('branchesV1')
        this.location = branchesV1.find((b) => b.id === id) || {}
      } catch (err) {
        this.$message({
          type: 'error',
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.branch.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    handleEdit() {
      this.$router.push({
        name: 'accounting-safe-management-edit',
        params: { id: this.$route.params.id }
      })
    }
  }
}
</script>

<style scoped>
.safe-history-container {
  padding: 0;
  margin: 0;
}
</style>
